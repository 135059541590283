import React from "react";
import Fade from "react-reveal/Fade";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SEO from "../components/seo";
import hero1 from "../images/macbook-pcr-calculator.png";
import chemDraw1 from "../images/student-chem-draw.png";
import microscopy from "../images/microscopy-export.png";
import bg1 from "../images/halftone-3@2x.png";
import bg2 from "../images/tracks-2-smaller@2x.png";
import StartTrialForm from "../components/StartTrialForm";
import { grey, indigo } from "@material-ui/core/colors";

export default () => {
  const matches = useMediaQuery("(min-width:1350px)");
  const mediumScreen = useMediaQuery("(min-width:1122px)");

  return (
    <Box>
      <SEO
        title="LabLog Lab Research Tools"
        description="Academic institutes trust LabLog as their digital lab notebook for secure, efficient research. Save time and keep laboratories productive across departments at your university."
      />
      <Box
        display={mediumScreen ? "flex" : "block"}
        margin="auto"
        width="100%"
        justifyContent="center"
        mt={4}
      >
        <Box maxWidth="550px" flexShrink={0} display="block" margin="auto">
          <Box
            color="#3d3d3d"
            component="h1"
            textAlign={mediumScreen ? "left" : "center"}
            fontSize="h3.fontSize"
          >
            <strong>Efficient laboratories take notes in LabLog</strong>
          </Box>

          <Box textAlign={mediumScreen ? "left" : "center"}>
            LabLog&apos;s digital research tools enable more efficient research.
          </Box>
        </Box>
        <Box maxWidth="550px" display="block" margin="auto">
          <img alt="LabLog PCR solution calculator" src={hero1} width="100%" />
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        p={mediumScreen ? 8 : 3}
        flexWrap="wrap"
      >
        <Fade up delay={200}>
          <Box
            maxWidth="450px"
            mt={2}
            bgcolor={grey[100]}
            p={3}
            minHeight="250px"
          >
            <p style={{ fontSize: "large", color: "#1976d2" }}>
              <strong>PCR &amp; qPCR</strong>
            </p>
            <p style={{ fontSize: "large" }}>
              Quickly calculate and record Polymerase Chain Reaction (PCR)
              experiments. Design qPCR templates directly inside your lab
              notebook.
            </p>
          </Box>
        </Fade>

        <Fade bottom delay={400}>
          <Box
            maxWidth="450px"
            mt={2}
            bgcolor={grey[100]}
            p={3}
            minHeight="250px"
          >
            <p style={{ fontSize: "large", color: "#1976d2" }}>
              <strong>Molecule Editor</strong>
            </p>
            <p style={{ fontSize: "large" }}>
              Draw molecules and reactions then export as SMILES, MDL, Symyx, or
              Accelrys Molfile. Powered by tools developed at the Novartis
              Institutes for BioMedical Research.
            </p>
          </Box>
        </Fade>

        <Fade bottom delay={600}>
          <Box
            maxWidth="450px"
            mt={2}
            bgcolor={grey[100]}
            p={3}
            minHeight="250px"
          >
            <p style={{ fontSize: "large", color: "#1976d2" }}>
              <strong>High-throughput screening</strong>
            </p>
            <p style={{ fontSize: "large" }}>
              Create cell-line and plasmid names with unique identifiers and
              custom templates.
            </p>
          </Box>
        </Fade>
      </Box>
      <Box
        height="500px"
        style={{
          backgroundImage: `url(${chemDraw1})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        overflow="hidden"
      ></Box>
      <Box
        display="flex"
        bgcolor={grey[100]}
        justifyContent="center"
        flexWrap="wrap"
        width="100%"
        pb={12}
        pt={12}
        pl={mediumScreen ? 12 : 2}
        pr={mediumScreen ? 12 : 2}
        mb={6}
      >
        <Box mt={2}>
          <Box fontWeight="500" fontSize="1.5em">
            Work with tools you already know
          </Box>
          <Box mt={2}>
            <CheckIcon fontSize="inherit" />
            &nbsp; Microsoft Live and OneDrive: Edit &amp; view MS Office
            documents
          </Box>
          <Box mt={2}>
            <CheckIcon fontSize="inherit" />
            &nbsp; Draw molecules and reactions | Export SMILES &amp; SMIRKS
          </Box>
          <Box mt={2}>
            <CheckIcon fontSize="inherit" />
            &nbsp; GraphPad Prism | Export and import data tables
          </Box>
          <Box mt={2}>
            <CheckIcon fontSize="inherit" />
            &nbsp; Search notes and file contents — Powered by Azure Cognitive
            Search
          </Box>
          <Box mt={2}>
            <CheckIcon fontSize="inherit" />
            &nbsp; Single Sign-On authentication
          </Box>
        </Box>
        {matches && <Box width="240px" flexShrink={0} />}
        <Box maxWidth="450px" mt={2}>
          <Box fontWeight="500" fontSize="1.5em">
            Support for inline images and tables
          </Box>
          <Box mt={2}>
            Insert and align images inside notes, add data tables, create PCR
            templates, link to local files &amp; folders, and more — right
            within LabLog.
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box
          maxWidth="600px"
          style={{
            backgroundImage: `url(${bg1}), url(${bg2})`,
            backgroundRepeat: "no-repeat, no-repeat",
            backgroundSize: "210px 187px, 210px 187px",
            backgroundPosition: "left bottom, right top",
          }}
        >
          <img
            alt="Microscopy Analysis Feature"
            src={microscopy}
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
          />
        </Box>
      </Box>
      <div style={{ marginTop: 0 }}>
        <Box
          color="#3d3d3d"
          component="h2"
          textAlign="center"
          alignContent="center"
          alignSelf="center"
          fontSize="h4.fontSize"
        >
          Get work done
        </Box>
        <div
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: matches ? "50%" : "90%",
          }}
        >
          <Box
            color="#3d3d3d"
            component="p"
            textAlign="center"
            alignContent="center"
            alignSelf="center"
            fontSize="h6.fontSize"
          >
            Share and collaborate with your team <br /> Sync data on-the-fly
          </Box>
        </div>
      </div>
      <Box
        style={{
          marginTop: 50,
          backgroundColor: indigo[50],
          paddingTop: 70,
          paddingBottom: 70,
        }}
        textAlign="center"
        fontSize="1.5em"
      >
        Try LabLog with your team
        <StartTrialForm />
      </Box>
    </Box>
  );
};
